
export default function form() {

  let inputs = document.querySelectorAll('input[type="text"], input[type="email"], input[type="tel"]');



  inputs.forEach(input => {
    let placeholder = '';
    input.addEventListener('focus', function(e) {
      placeholder = e.currentTarget.placeholder;
      e.currentTarget.placeholder = "";
    })
    input.addEventListener('blur', function(e) {
      e.currentTarget.placeholder = placeholder;
    })
  })

  const forms = document.querySelectorAll('.js-form');

  const message = {
    success: 'Спасибо, ваша заявка принята! Наш менеджер свяжется с вами в ближайшее время',
    failure: "Что-то пошло не так, обратитесь к администратору сайта",
  }


  if (forms.length > 0) {

    forms.forEach(form => {
      bindHandlerInputText(form, '.js-username');
      bindHandlerInputText(form, '.js-surname');
      bindHandlerInputText(form, '.js-sphere');

      bindPostData(form);
    });


    function bindHandlerInputText(form, selector) {

      const nameInput = form.querySelector(selector);
      const errorHtml = form.querySelector('.form__row-error');

      nameInput.addEventListener('input', function(e) {
        if (nameInput.value == '' || /\d/.test(nameInput.value)) {
          errorHtml.classList.add('active');
        } else {
          errorHtml.classList.remove('active');
        }
      })

    }



    function isValidEmail(email) {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    function bindPostData(form) {


      form.addEventListener('submit', function(e) {

        e.preventDefault();
        console.log('submit')


        const checkUsername = checkValidateText(form, '.js-username');
        const checkSurname = checkValidateText(form, '.js-surname');
        const checkSphere = checkValidateText(form, '.js-sphere');
        const checkEmail = checkValidateEmail(form);
        const checkPhone = checkValidateNumber(form)

        if (checkUsername && checkSurname && checkSphere && checkEmail && checkPhone) {

          console.log('все проверено')

          const btn = form.querySelector('button.btn');
          const modalThanks = document.querySelector('#modal-thanks');
          const modalRecord = document.querySelector('modal-record');
          console.log(modalThanks);
          btn.disabled = true;

          const formData = new FormData(form);

         // Итерируемся по парам ключ-значение в formData
        for (let pair of formData.entries()) {
          console.log(pair[0] + ': ' + pair[1]);
        }


          postData("vendor/action-form.php", formData)
          .then((data) => {
            console.log(data)
            modalThanks.classList.add('active');
            modalRecord.classList.remove('active');
          })
          .catch((err) => {
            console.log(err)
            // alert("Une erreur s'est produite, veuillez contacter via un messager qui vous convient / An error has occurred, please contact via a messenger convenient for you");
          })
          .finally(() => {
            btn.disabled = false;
            form.reset();
            setTimeout(() => {
              modalThanks.classList.remove('active');
            }, 4000);
          })

        } else {
          console.log('где-то есть ошибка');
        }

      })
    }

    const postData = async (url, data) => {
      const res = await fetch(url, {
        method: "POST",
        body: data,
      });

      return await res.json();
    };


    function checkValidateText(form, selector) {

      const nameInput = form.querySelector(selector);
      const errorHtml = form.querySelector('.form__row-error');


      if (nameInput.value == '' || /\d/.test(nameInput.value)) {
        errorHtml.classList.add('active');

        return false;
      } else {
        errorHtml.classList.remove('active');

        return true;
      }
    }


    function checkValidateNumber(form) {

      const phoneInput = form.querySelector('.js-phone');
      const errorHtml = form.querySelector('.form__row-error');


      if (phoneInput.value == '' || !(/\d/.test(phoneInput.value))) {
        errorHtml.classList.add('active');

        return false;
      } else {
        errorHtml.classList.remove('active');

        return true;
      }
    }




  function checkValidateEmail(form) {
    const emailInput = form.querySelector('.js-email');

      const errorHtml = form.querySelector('.form__row-error');

    if (!(isValidEmail(emailInput.value))) {
        errorHtml.classList.add('active');
        return false;
      } else {
        errorHtml.classList.remove('active');
        return true;
      }
    }
  }
}
