export default function modal() {

  // закрыть все модалки
  const modals = document.querySelectorAll('.modal');
  const body = document.querySelector('body');

  function closeAllModals() {
    modals.forEach(modal => {
      modal.classList.remove('active');
      body.classList.remove('lock');
    })
  }






  const btnRecords = document.querySelectorAll('.js-btn-record');


  if (btnRecords && btnRecords.length > 0) {
    const modal = document.querySelector('.modal#modal-record');


    btnRecords.forEach(btnRecord => {
      btnRecord.addEventListener('click', (e) => {

        //получить дата атрибут data-
        const placeBtnName = e.currentTarget.getAttribute('data-project');
        const placeBtn = document.getElementById('place-btn');
        placeBtn.value = placeBtnName;
        console.log(placeBtnName);

        e.preventDefault();
        modal.classList.add('active');
        body.classList.add('lock');
      })
    });
  }



   const close = document.querySelectorAll('.modal__close');


    close.forEach(cl => {
      cl.addEventListener('click', (e) => {
      const modal = e.currentTarget.closest('.modal');
      modal.classList.remove('active');
      body.classList.remove('lock');
    })
    })




    document.querySelectorAll('.modal').forEach(item => {

      item.addEventListener('click', (e) => {
        if (!(e.target.closest('.modal__content'))) {
          console.log('нет');
          e.currentTarget.classList.remove('active');
          body.classList.remove('lock');
        }
      })

    })


    document.addEventListener('keydown', function(e) {
      if (e.key === 'Escape') {
      //ваша функция закрытия окна
        closeAllModals()
      }
    });


}


