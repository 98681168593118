export default function switcher() {
  console.log('switcher')


  const select = document.querySelector('.switcher__select');

  const parent = document.querySelector('.switcher');

  select.addEventListener('click', (e) => {
    parent.classList.toggle('active');
  })

}
