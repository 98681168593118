export default function steps() {


  const delimiterWraps = document.querySelectorAll('.delimiter-wrap');


  if(delimiterWraps) {




  function phonesFunction() {



  delimiterWraps.forEach(delimiterWrap => {

    let more = delimiterWrap.nextElementSibling;

    let moreBtn = more.querySelector('.more__btn');

    console.log(moreBtn);


    const lis = delimiterWrap.querySelectorAll('.delimiter ~ li');

    lis.forEach(li => {
      console.log(li);
      li.style.display = 'none';
    })

    if (moreBtn) {


      moreBtn.addEventListener('click', function (e) {
        e.preventDefault();

          delimiterWrap.classList.toggle('opened');

          if (delimiterWrap.classList.contains('opened')) {


            lis.forEach(li => {
              console.log(li);
              li.style.display = 'block';
            })
            moreBtn.classList.add('opened');
          } else {
            moreBtn.classList.remove('opened');

            lis.forEach(li => {
              console.log(li);
              li.style.display = 'none';
            })
          }


      })
    }
    });

  }

  // брекпоинт для уничтжения swiper
  const breakpoint = window.matchMedia('(max-width: 768px)');


    //функция проверки
    const breakpointChecker = function () {

      if (breakpoint.matches === true) {
        console.log('тут вызываем нашу функцию');
        /* окно просмотра имеет ширину не больше 768px пикселей */
        phonesFunction()

      } else {
        console.log('а тут ее не нужно');
         /* окно просмотра имеет ширину больше 768px пикселей */

        // очищаем старые экземпляры и встроенные стили, когда они доступны

        // или / и ничего не делать
        return;
      }
    }


    // следим за изменениями размера области просмотра
    breakpoint.addListener(breakpointChecker);

    //старт
    breakpointChecker();







  }


}
